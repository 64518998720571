<template>
    <b-container fluid>
        <div class="section-title budget">Исполнение проекта</div>
        <b-tabs>
            <b-tab title="Таблица">
                <h6 class="m-1">Строительство пристройки на 528 мест в СОШ им. Б. Момышулы г. Павлодар</h6>
                <b-table :fields="tableFields" bordered no-border-collapse striped thead-class="bold_header" :items="tableData">
                    <template #thead-top="data">
                        <b-tr class="text-center">
                            <b-th colspan="3"></b-th>
                            <b-th colspan="5">Бюджетные средства, тыс. тенге</b-th>
                            <b-th></b-th>
                            <b-th></b-th>
                            <b-th></b-th>
                        </b-tr>
                    </template>
                    <template #cell(f1)="data">
                        <div class="text-center">
                            {{ data.value}}
                        </div>
                    </template>
                    <template #cell(f3)="data">
                        <div class="text-center">
                           <b-icon-check-circle v-if="data.item.f3" variant="success"></b-icon-check-circle>
                            <b-icon-x-circle v-else variant="warning"></b-icon-x-circle>
                        </div>
                    </template>
                    <template #cell(f4)="data">
                        <div class="text-right">
                            {{ $n(data.value)}}
                        </div>
                    </template>
                    <template #cell(f5)="data">
                        <div class="text-right">
                            {{ $n(data.value)}}
                        </div>
                    </template>
                    <template #cell(f6)="data">
                        <div class="text-right">
                            {{ $n(data.value)}}
                        </div>
                    </template>
                    <template #cell(f9)="data">
                        <div class="text-center">
                            {{ data.value}}
                        </div>
                    </template>
                    <template #cell(f11)="data">
                        <div class="text-left">
                            <a v-if="data.item.f11" href="#">{{ data.value }}</a>
                            <a v-else>{{ data.value }}</a>
                        </div>
                    </template>
                </b-table>
            </b-tab>
            <b-tab title="График">
                <chart></chart>
            </b-tab>
        </b-tabs>

    </b-container>
</template>

<script>
import chart from './GanttChart';
import {BIconCheckCircle, BIconXCircle} from 'bootstrap-vue'

export default {
    name: 'BudgetGantt',
    components: { chart, BIconCheckCircle, BIconXCircle },
    data() {
        return {
            tableFields: [
                {
                    key: 'f1',
                    label: '№'
                },
                {
                    key: 'f2',
                    label: 'Наименование задач'
                },
                {
                    key: 'f3',
                    label: 'Статус'
                },
                {
                    key: 'f4',
                    label: 'Утв. бюджет'
                },
                {
                    key: 'f5',
                    label: 'Прин. обязат-ва'
                },
                {
                    key: 'f6',
                    label: 'Экон-ия'
                },
                {
                    key: 'f7',
                    label: 'Освоено (касса)'
                },
                {
                    key: 'f8',
                    label: '% освоения'
                },
                {
                    key: 'f9',
                    label: 'Срок завер-ния'
                },
                {
                    key: 'f10',
                    label: 'Ответ. Исп-ль'
                },
                {
                    key: 'f11',
                    label: 'Подтверждающие документы'
                }
            ],
            tableData: [
                {
                    'f1': 1,
                    'f2': 'Конкурсные процедуры',
                    'f3': true,
                    'f9': '02.04.2022',
                    'f10': 'Азатов А.А.',
                    'f11': 'Договор на СМР от 04.03.2021 г.№80/20'
                },
                {
                    'f1': 2,
                    'f2': 'Разрешение',
                    'f3': true,
                    'f9': '21.05.2022',
                    'f10': 'Азатов А.А.',
                    'f11': 'Талон на СМР №594'
                },
                {
                    'f1': 3,
                    'f2': 'Земляные работы',
                    'f4': '247615',
                    'f5': '234789',
                    'f6': '12826',
                    'f9': '30.06.2022',
                    'f10': 'Сабитов С.С.',
                },
                {
                    'f1': 4,
                    'f2': 'Устройство фундамента',
                    'f4': '222853',
                    'f5': '211310',
                    'f6': '11543',
                    'f9': '31.06.2022',
                    'f10': 'Сабитов С.С.',
                },
                {
                    'f1': 5,
                    'f2': 'Устройство нар.',
                    'f4': '185711',
                    'f5': '176092',
                    'f6': '9619',
                    'f9': '30.09.2022',
                    'f10': 'Сабитов С.С.',
                    'f11': ''
                },
                {
                    'f1': 6,
                    'f2': 'Устройство кровли',
                    'f4': '309519',
                    'f5': '293487',
                    'f6': '16032',
                    'f9': '15.10.2022',
                    'f10': 'Сабитов С.С.',
                    'f11': ''
                },
                {
                    'f1': 7,
                    'f2': 'Подведение инженерных сетей',
                    'f4': '132940',
                    'f5': '126054',
                    'f6': '6886',
                    'f9': '01.11.2022',
                    'f10': 'Сабитов С.С.',
                    'f11': ''
                },
                {
                    'f1': 8,
                    'f2': 'Монтаж окон',
                    'f4': '173330',
                    'f5': '164352',
                    'f6': '8978',
                    'f9': '10.11.2022',
                    'f10': 'Сабитов С.С.',
                    'f11': ''
                },
                {
                    'f1': 9,
                    'f2': 'Внутренняя отделка',
                    'f4': '99046',
                    'f5': '93916',
                    'f6': '5130',
                    'f9': '15.12.2022',
                    'f10': 'Сабитов С.С.',
                    'f11': ''
                },
                {
                    'f1': 10,
                    'f2': 'Ввод объекта',
                    'f9': '15.01.2023',
                    'f10': 'Омаров О.О.',
                    'f11': ''
                },
                {
                    'f1': 11,
                    'f2': 'Передача объекта в эксплуатацию',
                    'f9': '20.02.2023',
                    'f10': 'Омаров О.О.',
                    'f11': ''
                }
            ]

        };
    }
};
</script>

<style>
.hc {
    height: 600px;
}
</style>