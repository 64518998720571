<template>
    <div>
        <highcharts :constructor-type="'ganttChart'" :options="chartOptions"></highcharts>
    </div>
</template>

<script>

export default {

    data() {
        return {
            chartOptions: {
                backgroundColor: 'transparent',
                lang: {
                    loading: 'Загрузка...',
                    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                    shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек']
                },
                xAxis: {
                    min: Date.UTC(2021, 10, 17),
                    max: Date.UTC(2023, 10, 30)
                },

                series: [{
                    name: 'Строительство пристройки на 528 мест в СОШ им. Б. Момышулы г. Павлодар',
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}',
                        align: 'left',
                        style: {
                            fontSize: '1.4em',
                            fontWeight: '60',
                            color: '#0E304C',
                            textOutline: "0.5px",
                        }
                    },
                    data: [
                        {
                            'id': 1,
                            'name': 'Конкурсные процедуры',
                            'start': Date.UTC(2022, 2, 4),
                            'end': Date.UTC(2022, 4, 2)
                        },
                        {
                            'id': 2,
                            'name': 'Разрешение на СМР',
                            'start': Date.UTC(2022, 5, 20),
                            'end': Date.UTC(2022, 5, 21)
                        },
                        {
                            'id': 3,
                            'name': 'Земляные работы',
                            'start': Date.UTC(2022, 5, 21),
                            'end': Date.UTC(2022, 6, 30)
                        },
                        {
                            'id': 4,
                            'name': 'Устройство фундамента',
                            'start': Date.UTC(2022, 6, 1),
                            'end': Date.UTC(2022, 6, 30)
                        },
                        {
                            'id': 5,
                            'name': 'Устройство нар. и внутр. Стен',
                            'start': Date.UTC(2022, 7, 1),
                            'end': Date.UTC(2022, 9, 30)
                        },
                        {
                            'id': 6,
                            'name': 'Устройство кровли',
                            'start': Date.UTC(2022, 9, 30),
                            'end': Date.UTC(2022, 10, 15)
                        },
                        {
                            'id': 7,
                            'name': 'Подведение инженерных сетей',
                            'start': Date.UTC(2022, 10, 15),
                            'end': Date.UTC(2022, 11, 1)
                        },
                        {
                            'id': 8,
                            'name': 'Монтаж окон и дверей',
                            'start': Date.UTC(2022, 11, 1),
                            'end': Date.UTC(2022, 11, 10)
                        },
                        {
                            'id': 9,
                            'name': 'Внутренняя отделка',
                            'start': Date.UTC(2022, 11, 10),
                            'end': Date.UTC(2022, 12, 15)
                        },
                        {
                            'id': 10,
                            'name': 'Ввод объекта в эксплуатацию',
                            'start': Date.UTC(2023, 2, 1),
                            'end': Date.UTC(2023, 2, 15)
                        },
                        {
                            'id': 11,
                            'name': 'Передача объекта в эксплуатацию',
                            'start': Date.UTC(2023, 2, 15),
                            'end': Date.UTC(2023, 2, 20)
                        }
                    ]
                }],

                title: {
                    text: 'Строительство пристройки на 528 мест в СОШ им. Б. Момышулы г. Павлодар'
                }
            }
        };
    }
};
</script>

<style>

</style>